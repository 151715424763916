<template>
    <ion-header>
        <ion-toolbar>
            <ion-title>{{ title }}</ion-title>
            <ion-buttons slot="end">
                <ion-button @click="close"><ion-icon :icon="closeOutline" /></ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-no-padding">
        <div v-if="boxType === 'checkbox'">
            <ion-item lines="full" v-for="option of data" :key="option.value" mode="md">
                <ion-label color="primary">{{ option.text }}</ion-label>
                <ion-checkbox 
                    slot="start" 
                    @update:modelValue="option.isChecked = $event" 
                    :modelValue="option.isChecked" 
                    mode="md" 
                    color="primary"></ion-checkbox>
            </ion-item>
        </div>

        <ion-radio-group v-if="boxType === 'radio'" @ionChange="radioSelected" :value="radioValue">
            <ion-item lines="full" v-for="option of data" :key="option.value" mode="md">
                <ion-label color="primary">{{ option.text }}</ion-label>
                <ion-radio
                    slot="start"
                    :value="option.value"
                    :disabled="option.disabled && !adminOverride"
                    mode="md"
                    color="primary"></ion-radio>
            </ion-item>
        </ion-radio-group>
    </ion-content>
</template>

<script lang="ts">

import {
    IonIcon,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton, 
    IonRadio,
    IonRadioGroup,
    IonCheckbox,
    IonItem,
    IonLabel,
    modalController
} from '@ionic/vue';
import {computed, defineComponent} from "vue";
import { closeOutline } from 'ionicons/icons';
import { SelectOption } from '@/models/SelectOptionModel';

export default defineComponent({
    name: 'SelectModal',
    props: {
        title: {
            type: String,
            default: ""
        },
        data: {
            type: Array,
            default: [] as SelectOption[]
        },
        boxType: {
            type: String,
            default: ""
        },
        adminOverride: {
          type: Boolean,
          default: false
        }
    },
    components: {
        IonIcon,
        IonContent,
        IonHeader,
        IonTitle, 
        IonToolbar,
        IonButtons,
        IonButton,
        IonRadio,
        IonRadioGroup,
        IonCheckbox,
        IonItem,
        IonLabel
    },
    setup(props: any) {
        const close = () => {
            modalController.dismiss(props.data);
        }
        
        const radioSelected = (e: any) => {
            const selectedVal = e.target.value;
            props.data.map((option: any) => option.isChecked = option.value === selectedVal);
            close();
        }
        
        return {
            closeOutline,
            close,
            radioSelected,
            radioValue: computed(() => props.data.find((option: SelectOption) => option.isChecked)?.value)
        }
    }
});
</script>

<style scoped>
ion-content {
    --padding-start: 0;
    --padding-end: 0;
}
ion-title, ion-toolbar ion-icon {
    color: var(--ion-color-primary);
}

ion-title {
    padding-left: 26px;
    padding-right: 26px;
}

ion-toolbar ion-button {
    --background-hover-opacity: 0;
}

.ltr ion-checkbox, .ltr ion-radio {
    margin-right: 15px;
    margin-left: 0;
}

.rtl ion-checkbox, .rtl ion-radio {
    margin-left: 15px;
    margin-right: 0;
}
</style>