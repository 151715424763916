import {modalController} from "@ionic/vue";
import SelectModal from "@/components/SelectModal.vue";
import {SelectOption} from "@/models/SelectOptionModel";
import ImageSelectModal from "@/components/ImageSelectModal.vue";
import {ImageGroupId} from "@/models/ImageGroupTypes";
import VideoSelectModal from '@/components/Video/VideoSelectModal.vue';

export async function openSelectModal(title: string, boxType: string, data: SelectOption[], allowAdminOverride: boolean) {
    const modal = await modalController.create({
        component: SelectModal,
        cssClass: 'custom-modal',
        componentProps: {
            title: title,
            data: data,
            boxType: boxType,
            adminOverride: allowAdminOverride
        },
        backdropDismiss: false,
        mode: "md"
    });
    
    await modal.present();

    return modal;
}

export async function openSelectImageModal(title: string, groupId: ImageGroupId) {
    const modal = await modalController.create({
        component: ImageSelectModal,
        componentProps: {
            title: title,
            groupId: groupId
        },
        cssClass: 'custom-modal',
        backdropDismiss: false,
        mode: "md"
    });

    await modal.present();

    return modal;
}

// export async function openVideoModal(id: string, firstVideo: boolean) {
export async function openVideoModal() {
    const modal = await modalController.create({
        component: VideoSelectModal,
        // componentProps: {
        //     journeyId: id,
        //     firstVideo: firstVideo
        // },
        cssClass: 'custom-modal',
        backdropDismiss: false,
        mode: "md"
    });

    await modal.present();

    return modal;
}