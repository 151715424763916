

import {
    IonIcon,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
    modalController
} from '@ionic/vue';
import {computed, defineComponent, onBeforeMount, ref} from "vue";
import { closeOutline } from 'ionicons/icons';
import {GET} from "@/services/HTTP";
import {ImageGroup} from "@/models/ImageGroupModel";
import {Image} from "@/models/ImageModel";
import {ImageGroupId} from "@/models/ImageGroupTypes";
import {useStore} from "vuex";
import {getTranslatedNumber} from "@/utils/NumberTranslate";
import DirectionIndicator from "@/components/DirectionIndicator.vue";

export default defineComponent({
    name: 'ImageSelectModal',
    props: {
        title: {
            type: String,
            default: ''
        },
        groupId: {
            type: String,
            default: ''
        }
    },
    components: {
        DirectionIndicator,
        IonIcon,
        IonContent,
        IonHeader,
        IonTitle, 
        IonToolbar,
        IonButtons,
        IonButton,
        IonItem,
        IonLabel
    },
    setup(props: any) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);
        const imageGroups = ref([] as ImageGroup[]);
        const images = ref([] as Image[]);
        const showImageSelection = ref(false);
        const isImage = computed(() => props.groupId === ImageGroupId.JourneyImages);
        
        onBeforeMount(async () => {
            imageGroups.value = await GET('/imageGroups/' + props.groupId + "?published=true", false);
        });
        
        const close = (image: Image) => {
            modalController.dismiss(image);
        }
        
        const loadImages = (imgs: Image[]) => {
            images.value = imgs;
            showImageSelection.value = true;
        }

        return {
            closeOutline,
            close,
            imageGroups,
            images,
            showImageSelection,
            loadImages,
            language
        }
    }
});
