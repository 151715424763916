
import {useStore} from 'vuex';
import {computed, defineComponent, ref} from 'vue';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader, IonIcon, IonItem,
  IonTitle,
  IonToolbar,
  modalController,
} from '@ionic/vue';
import {closeOutline} from 'ionicons/icons';
import VideoLink from '@/components/Video/VideoLink.vue';

export default defineComponent({
  name: "VideoSelectModal",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonItem,
    VideoLink
  },
  setup() {
    const store = useStore();
    const language = computed(() => store.getters['app/language']);
    const videoUrl = ref('');
    const videoLabel = ref('');
    
    const close = () => {
      modalController.dismiss( {videoUrl, videoLabel} );
    }

    return {
      closeOutline,
      store,
      language,
      videoLabel,
      videoUrl,
      close,
    }
  }
});
