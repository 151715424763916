<template>
  <ion-item lines="full" mode="md" >
    <div class="input-row">
      <ion-label class="video-label" for="videoLabel">{{videoLabel}}</ion-label>
      <ion-input id="videoLabel" :value="tabVideoLabel" :placeholder="videoLabel" 
                 @input="$emit('update:tabVideoLabel', $event.target.value)" v-if="!disabled"></ion-input>
      <div style="padding: 10px 0 0 8px;" v-else>
        <span>{{ tabVideoLabel }}</span>
      </div>
    </div>
  </ion-item>
  <ion-item lines="full" mode="md">
    <div class="input-row">
      <ion-label class="video-label" for="videoUrl">{{videoUrl}}</ion-label>
      <ion-input id="videoUrl" :value="tabVideoUrl" :placeholder="videoUrl"
                 @input="$emit('update:tabVideoUrl', $event.target.value)" v-if="!disabled"></ion-input>
      <div style="padding: 10px 0 0 8px;" v-else>
        <span>{{ tabVideoUrl }}</span>
      </div>
    </div>
  </ion-item>
</template>
<script lang="ts">

import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {IonInput, IonItem, IonLabel} from '@ionic/vue';

export default defineComponent({
  name: "VideoLink",
  props: {
    tabVideoLabel: {
      type: String,
      required: false
    },
    tabVideoUrl: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  emits: ['update:tabVideoLabel', 'update:tabVideoUrl'],
  components: {
    IonItem,
    IonInput,
    IonLabel
  },
  setup() {
    const store = useStore();
    const language = computed(() => store.getters['app/language']);
    const videoLabel = language.value.journeyVideo1Label;
    const videoUrl = language.value.journeyVideo1Url;

    return {
      store,
      language,
      videoLabel,
      videoUrl
    }
  }
});

</script>

<style scoped>
.video-label {
  padding-left: 8px;
  color: var(--ion-color-primary);
  width: 100%;
}

.input-row {
  flex-direction: column;
  gap: 8px;
  min-width: 100px;
  flex-grow: 1;
  padding: 8px 0 12px 0;
}

</style>