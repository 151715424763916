

import {
    IonIcon,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton, 
    IonRadio,
    IonRadioGroup,
    IonCheckbox,
    IonItem,
    IonLabel,
    modalController
} from '@ionic/vue';
import {computed, defineComponent} from "vue";
import { closeOutline } from 'ionicons/icons';
import { SelectOption } from '@/models/SelectOptionModel';

export default defineComponent({
    name: 'SelectModal',
    props: {
        title: {
            type: String,
            default: ""
        },
        data: {
            type: Array,
            default: [] as SelectOption[]
        },
        boxType: {
            type: String,
            default: ""
        },
        adminOverride: {
          type: Boolean,
          default: false
        }
    },
    components: {
        IonIcon,
        IonContent,
        IonHeader,
        IonTitle, 
        IonToolbar,
        IonButtons,
        IonButton,
        IonRadio,
        IonRadioGroup,
        IonCheckbox,
        IonItem,
        IonLabel
    },
    setup(props: any) {
        const close = () => {
            modalController.dismiss(props.data);
        }
        
        const radioSelected = (e: any) => {
            const selectedVal = e.target.value;
            props.data.map((option: any) => option.isChecked = option.value === selectedVal);
            close();
        }
        
        return {
            closeOutline,
            close,
            radioSelected,
            radioValue: computed(() => props.data.find((option: SelectOption) => option.isChecked)?.value)
        }
    }
});
