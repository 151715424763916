<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>{{ videoLabel }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="close"><ion-icon :icon="closeOutline" /></ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-no-padding">
    <video-link v-model:tab-video-label="videoLabel"
                v-model:tab-video-url="videoUrl"></video-link>
    <ion-item lines="none" mode="md">
      <button class="save" @click="close">
        Save
      </button>
    </ion-item>
  </ion-content>
</template>

<script lang="ts">
import {useStore} from 'vuex';
import {computed, defineComponent, ref} from 'vue';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader, IonIcon, IonItem,
  IonTitle,
  IonToolbar,
  modalController,
} from '@ionic/vue';
import {closeOutline} from 'ionicons/icons';
import VideoLink from '@/components/Video/VideoLink.vue';

export default defineComponent({
  name: "VideoSelectModal",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonItem,
    VideoLink
  },
  setup() {
    const store = useStore();
    const language = computed(() => store.getters['app/language']);
    const videoUrl = ref('');
    const videoLabel = ref('');
    
    const close = () => {
      modalController.dismiss( {videoUrl, videoLabel} );
    }

    return {
      closeOutline,
      store,
      language,
      videoLabel,
      videoUrl,
      close,
    }
  }
});
</script>

<style scoped>
.save {
  margin-top: 12px;
  width: 100%;
  background-color: var(--ion-color-primary);
  color: var(--color-step-1000);
  padding: 12px 0;
  font-size: 18px;
  border-radius: 5px;
}
</style>