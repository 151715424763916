

import {computed, defineComponent} from 'vue';
import {useStore} from 'vuex';
import {IonInput, IonItem, IonLabel} from '@ionic/vue';

export default defineComponent({
  name: "VideoLink",
  props: {
    tabVideoLabel: {
      type: String,
      required: false
    },
    tabVideoUrl: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  emits: ['update:tabVideoLabel', 'update:tabVideoUrl'],
  components: {
    IonItem,
    IonInput,
    IonLabel
  },
  setup() {
    const store = useStore();
    const language = computed(() => store.getters['app/language']);
    const videoLabel = language.value.journeyVideo1Label;
    const videoUrl = language.value.journeyVideo1Url;

    return {
      store,
      language,
      videoLabel,
      videoUrl
    }
  }
});

